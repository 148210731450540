<template>
  <div style="padding: 10px" class="bg">
    <span class="menu_title">预设目标</span>
    <el-form :label-position="labelPosition" label-width="auto" :model="form" :rules="rules" ref="form">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="招募令" prop="recruit">
            <el-input-number controls-position="right" size="medium" v-model="form.recruit"
                             placeholder="建议：3000"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="宝箱积分" prop="box">
            <el-input-number controls-position="right" size="medium" v-model="form.box"
                             placeholder="建议：31500"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="金鱼竿" prop="fishGod">
            <el-input-number controls-position="right" size="medium" v-model="form.fishGod"
                             placeholder="建议：800"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="金砖" prop="gold">
            <el-input-number controls-position="right" size="medium" v-model="form.gold"
                             placeholder="建议：250000"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动开始日期" prop="deadLine">
            <el-date-picker v-model="form.deadLine" @change="dateCompute"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <span class="menu_title">现有资源</span>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="现有招募令" prop="recruitCount">
            <el-input-number controls-position="right" size="medium" v-model="form.recruitCount"
                             placeholder="仓库中的招募令"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="现有金砖" prop="goldCount">
            <el-input-number controls-position="right" size="medium" v-model="form.goldCount"
                             placeholder="请输入金砖个数"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="现有金鱼竿" prop="fishGodCount">
            <el-input-number controls-position="right" size="medium" v-model="form.fishGodCount"
                             placeholder="请输入金鱼竿个数"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="现有宝箱积分" prop="boxCount">
            <el-input-number clearable controls-position="right" size="medium" v-model="form.boxCount"
                             placeholder="请输入宝箱积分"></el-input-number>
            <span style="font-weight: bold;color: #409EFF;cursor: pointer" @click="computeBoxCount">计算</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>

      <span class="menu_title">其他信息</span>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="月度竞技场已有积分" prop="monthPkCount">
            <el-input-number controls-position="right" size="medium" v-model="form.monthPkCount"
                             placeholder=""></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="月度钓鱼(普通)完成次数" prop="monthFishNormalCount">
            <el-input-number clearable controls-position="right" size="medium" v-model="form.monthFishNormalCount"
                             placeholder=""></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="月度钓鱼(高级)完成次数" prop="monthFishSeniorCount">
            <el-input-number controls-position="right" size="medium" v-model="form.monthFishSeniorCount"
                             placeholder=""></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="爬塔还可以获得的招募令" prop="recruitTower">
            <el-input-number controls-position="right" size="medium" v-model="form.recruitTower"
                             placeholder=""></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>

      <span class="menu_title">万能的氪金</span>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="是否有终身卡" prop="hasForeverCard">
            <el-radio-group v-model="form.hasForeverCard">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否购买每日1元礼包" prop="hasOneYuanPerDay">
            <el-radio-group v-model="form.hasOneYuanPerDay">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="是否有周卡" prop="hasWeekCard">
            <el-radio-group v-model="form.hasWeekCard">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="form.hasWeekCard === 1">
          <el-form-item label="当前周卡第几天" prop="weekCardDay">
            <el-input-number controls-position="right" size="medium" v-model="form.weekCardDay"
                             placeholder=""></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="未来还将购买几个周卡" prop="weekCardCount">
            <el-input-number controls-position="right" size="medium" v-model="form.weekCardCount"
                             @focus="weekMaxCompute"
                             placeholder="" :max="weekMax"></el-input-number>
            <img src="../../assets/help_logo.png" @click="otherHelp" alt="" style="width: 8%;cursor: pointer"
                 title="若不清楚填几可以输入9999，系统将自动计算可购买的最大个数">
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="是否有月卡" prop="hasMonthCard">
            <el-radio-group v-model="form.hasMonthCard">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="form.hasMonthCard === 1">
          <el-form-item label="当前月卡第几天" prop="monthCardDay">
            <el-input-number controls-position="right" size="medium" v-model="form.monthCardDay"
                             placeholder=""></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="未来还将购买几个月卡" prop="monthCardCount">
            <el-input-number controls-position="right" size="medium" v-model="form.monthCardCount"
                             @focus="monthMaxCompute"
                             placeholder="" :max="monthMaX"></el-input-number>
            <img src="../../assets/help_logo.png" @click="otherHelp" alt="" style="width: 8%;cursor: pointer"
                 title="若不清楚填几可以输入9999，系统将自动计算可购买的最大个数">
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <!--        <el-col :span="8">-->
        <!--          <el-form-item label="是否有128通行证" prop="hasPassCard">-->
        <!--            <el-radio-group v-model="form.hasPassCard">-->
        <!--              <el-radio :label="1">是</el-radio>-->
        <!--              <el-radio :label="0">否</el-radio>-->
        <!--            </el-radio-group>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
        <!--        <el-col :span="8" v-if="form.hasPassCard === 1">-->
        <!--          <el-form-item label="当前通行证等级" prop="passCardLevel">-->
        <!--            <el-input-number controls-position="right" size="medium" v-model="form.passCardLevel"-->
        <!--                             placeholder=""></el-input-number>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
        <!--        <el-col :span="8" v-if="form.hasPassCard === 1">-->
        <!--          <el-form-item label="当前通行证剩余天数" prop="passCardDay">-->
        <!--            <el-input-number controls-position="right" size="medium" v-model="form.passCardDay"-->
        <!--                             placeholder=""></el-input-number>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
        <el-col :span="8">
          <el-form-item label="未来还将购买几个128通行证" prop="passCardCount">
            <el-input-number controls-position="right" size="medium" v-model="form.passCardCount"
                             @focus="pass128Compute"
                             placeholder="" :max="pass128Max"></el-input-number>
            <img src="../../assets/help_logo.png" alt="" @click="passHelp" style="width: 8%;cursor: pointer"
                 title="若不清楚填几可以输入9999，系统将自动计算可购买的最大个数">
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider></el-divider>
    </el-form>
    <span class="menu_title">每日资源获取情况（根据自己情况修改）若无数据<span style="cursor: pointer;color: red"
                                                                             @click="load">点击刷新</span></span>
    <el-table
            v-loading="loading"
            :data="tableData"
            border="bold"
            stripe
            style="width: 100%">
      <el-table-column prop="weekShow" label="资源获取" width="150" align="center">
        <el-table-column prop="weekShow" label="星期" align="center" width="150"></el-table-column>
      </el-table-column>
      <el-table-column align="center"
                       label="招募令/个">
        <el-table-column align="center"
                         prop="recruitKing"
                         label="咸王">
        </el-table-column>
        <el-table-column align="center"
                         prop="recruitQuestion"
                         label="答题">
        </el-table-column>
      </el-table-column>
      <el-table-column align="center"
                       label="宝箱/分（是积分哦，不是个）">
        <el-table-column align="center"
                         prop="boxKing"
                         label="咸王">
        </el-table-column>
        <el-table-column align="center"
                         prop="boxQuestion"
                         label="答题">
        </el-table-column>
        <el-table-column align="center"
                         prop="boxHanging"
                         label="挂机">
        </el-table-column>
        <el-table-column align="center"
                         prop="boxPk"
                         label="竞技场">
        </el-table-column>
      </el-table-column>
      <el-table-column align="center"
                       label="金砖/个">
        <el-table-column align="center"
                         prop="goldKing"
                         label="咸王">
        </el-table-column>
        <el-table-column align="center"
                         prop="goldQuestion"
                         label="答题">
        </el-table-column>
        <el-table-column align="center"
                         prop="goldSaltBottle"
                         label="盐罐">
        </el-table-column>
        <el-table-column align="center"
                         prop="goldPk"
                         label="竞技场">
        </el-table-column>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-divider></el-divider>
    <el-row>
      <span class="dialog-footer">
        <el-button @click="resetData">重置</el-button>
        <el-button type="primary" @click="save">计算</el-button>
      </span>
    </el-row>
    <!--计算宝箱弹窗-->
    <el-dialog title="宝箱计算" v-model="dialogVisibleBox" width="30%">
      <el-form :model="formBox">
        <el-form-item label="木质宝箱">
          <el-input-number controls-position="right" size="medium" v-model="formBox.boxWoodCount"></el-input-number>
        </el-form-item>
        <el-form-item label="青铜宝箱">
          <el-input-number controls-position="right" size="medium" v-model="formBox.boxBronzeCount"></el-input-number>
        </el-form-item>
        <el-form-item label="黄金宝箱">
          <el-input-number controls-position="right" size="medium" v-model="formBox.boxGoldCount"></el-input-number>
        </el-form-item>
        <el-form-item label="铂金宝箱">
          <el-input-number controls-position="right" size="medium" v-model="formBox.boxPlatinumCount"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisibleBox = false">取 消</el-button>
            <el-button type="primary" @click="computeBox">确 定</el-button>
          </span>
      </template>
    </el-dialog>
    <!--周资源获取编辑-->
    <el-dialog title="宝箱计算" v-model="dialogVisibleWeek" width="80%">
      <el-form :model="formWeek" :label-position="labelPosition" label-width="auto">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="星  期" prop="weekShow">
              <el-input size="medium" v-model="formWeek.weekShow" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="招募令-咸王获取" prop="recruitKing">
              <el-input-number controls-position="right" size="medium" v-model="formWeek.recruitKing"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="招募令-答题获取" prop="recruitQuestion">
              <el-input-number controls-position="right" size="medium"
                               v-model="formWeek.recruitQuestion"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="宝箱积分-咸王获取" prop="boxKing">
              <el-input-number controls-position="right" size="medium" v-model="formWeek.boxKing"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="宝箱积分-答题获取" prop="boxQuestion">
              <el-input-number controls-position="right" size="medium" v-model="formWeek.boxQuestion"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="宝箱积分-挂机获取" prop="boxHanging">
              <el-input-number controls-position="right" size="medium" v-model="formWeek.boxHanging"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="宝箱积分-竞技场获取" prop="boxPk">
              <el-input-number controls-position="right" size="medium" v-model="formWeek.boxPk"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="金砖-咸王获取" prop="goldKing">
              <el-input-number controls-position="right" size="medium" v-model="formWeek.goldKing"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="金砖-答题获取" prop="goldQuestion">
              <el-input-number controls-position="right" size="medium"
                               v-model="formWeek.goldQuestion"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="金砖-盐罐获取" prop="goldSaltBottle">
              <el-input-number controls-position="right" size="medium"
                               v-model="formWeek.goldSaltBottle"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="金砖-竞技场获取" prop="goldPk">
              <el-input-number controls-position="right" size="medium" v-model="formWeek.goldPk"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisibleWeek = false">取 消</el-button>
            <el-button type="primary" @click="saveWeek">确 定</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  name: 'Dnf',
  components: {},
  data() {
    return {
      tableData: [],
      weekMax: 999,
      monthMaX: 99,
      pass128Max: 99,
      labelPosition: 'right',
      formWeek: {
        weekShow: '',
        recruitKing: 0,
        recruitQuestion: 0,
        boxKing: 0,
        boxHanging: 0,
        boxQuestion: 0,
        boxPk: 0,
        goldKing: 0,
        goldQuestion: 0,
        goldPk: 0,
        goldSaltBottle: 0
      },
      formBox: {
        boxWoodCount: 0,
        boxBronzeCount: 0,
        boxGoldCount: 0,
        boxPlatinumCount: 0,
      },
      form: {
        recruit: 3000,
        box: 31500,
        fishGod: '',
        gold: '',
        deadLine: '',
        recruitCount: '',
        recruitTower: 0,
        boxCount: '',
        goldCount: '',
        fishGodCount: '',
        monthPkCount: 0,
        monthFishNormalCount: 0,
        monthFishSeniorCount: 0,
        hasForeverCard: 0,
        hasOneYuanPerDay: 0,
        hasWeekCard: 0,
        weekCardDay: 1,
        weekCardCount: 0,
        hasMonthCard: 0,
        monthCardDay: 1,
        monthCardCount: 0,
        hasPassCard: 0,
        passCardLevel: 1,
        passCardDay: 1,
        passCardCount: 0,
      },
      loading: true,
      dialogVisible: false,
      dialogVisibleBox: false,
      dialogVisibleWeek: false,
      search: {},
      query: {},
      currentPage: 1,
      pageSize: 10,
      total: 0,
      rules: {
        recruit: [
          {required: true, message: '请输入招募令目标数，建议：3000', trigger: 'blur'},
        ],
        box: [
          {required: true, message: '请输入宝箱积分目标数，建议：31500', trigger: 'blur'},
        ],
        fishGod: [
          {required: true, message: '请输入金鱼竿目标数，建议：800', trigger: 'blur'},
        ],
        gold: [
          {required: true, message: '请输入金砖目标数，建议：250000', trigger: 'blur'},
        ],
        deadLine: [
          {required: true, message: '请输入截止日期', trigger: 'blur'}
        ],
        recruitCount: [
          {required: true, message: '请输入现有招募数', trigger: 'blur'}
        ],
        boxCount: [
          {required: true, message: '请输入现有宝箱积分', trigger: 'blur'}
        ],
        goldCount: [
          {required: true, message: '请输入金砖个数', trigger: 'blur'}
        ],
        fishGodCount: [
          {required: true, message: '请输入金鱼竿个数', trigger: 'blur'}
        ],
      },
    }
  },
  created() {
    this.load();
    // this.loadWeekResource();
  },
  methods: {
    passHelp() {
      this.$alert('若不清楚填几可以输入9999，系统将自动计算可购买的最大个数，通行证周期与自然月不匹配，可能会存在一些误差', '提示', {
        confirmButtonText: '确定',
      });
    },
    otherHelp() {
      this.$alert('若不清楚填几可以输入9999，系统将自动计算可购买的最大个数', '提示', {
        confirmButtonText: '确定',
      });
    },
    pass128Compute() {
      const days = dayjs(this.form.deadLine).diff(new Date(), 'day');
      if (this.form.hasPassCard === 1 && this.form.passCardDay) {
        this.pass128Max = Number.parseInt((days - this.form.passCardDay) / 30);
      } else {
        this.pass128Max = Number.parseInt(days / 30);
      }
    },
    monthMaxCompute() {
      const days = dayjs(this.form.deadLine).diff(new Date(), 'day');
      if (this.form.hasMonthCard === 1) {
        this.monthMaX = Number.parseInt((days - (30 - this.form.monthCardDay)) / 30);
      } else {
        this.monthMaX = Number.parseInt(days / 30);
      }
    },
    weekMaxCompute() {
      const days = dayjs(this.form.deadLine).diff(new Date(), 'day');
      if (this.form.hasWeekCard === 1) {
        this.weekMax = Number.parseInt((days - (7 - this.form.weekCardDay)) / 7);
      } else {
        this.weekMax = Number.parseInt(days / 7);
      }
    },
    dateCompute() {
      /*截止日期与当天的差异天数*/
      const days = dayjs(this.form.deadLine).diff(new Date(), 'day');
      if (days <= 0) {
        this.$message({
          type: "error",
          message: "请选择大于今天的日期"
        });
        return;
      }
      this.weekMax = (days - (7 - this.form.weekCardDay)) / 7;
    },
    /*计算宝箱积分*/
    computeBoxCount() {
      this.dialogVisibleBox = true;
      this.formBox = {
        boxWoodCount: 0,
        boxBronzeCount: 0,
        boxGoldCount: 0,
        boxPlatinumCount: 0,
      };
    },
    computeBox() {
      const that = this;
      that.form.boxCount = that.formBox.boxWoodCount +
          that.formBox.boxBronzeCount * 10 +
          that.formBox.boxGoldCount * 20 +
          that.formBox.boxPlatinumCount * 50;
      that.dialogVisibleBox = false;
    },
    resetData() {
      this.form = {
        recruit: 0,
        box: 0,
        fishGod: 0,
        gold: 0,
        deadLine: '',
        recruitCount: 0,
        recruitTower: 0,
        boxCount: 0,
        goldCount: 0,
        fishGodCount: 0,
        monthPkCount: 0,
        monthFishNormalCount: 0,
        monthFishSeniorCount: 0,
        hasForeverCard: 0,
        hasOneYuanPerDay: 0,
        hasWeekCard: 0,
        weekCardDay: 1,
        weekCardCount: 0,
        hasMonthCard: 0,
        monthCardDay: 1,
        monthCardCount: 0,
        hasPassCard: 0,
        passCardLevel: 1,
        passCardCount: 0,
      }
    },
    resetSearch() {
      this.search = {}
      this.load()
    },
    load() {
      let userId = this.$route.query.userId;
      this.form.userId = userId;
      // let userStr = sessionStorage.getItem("user") || "{}"
      // let user = JSON.parse(userStr)
      this.loading = true
      request.get("/saltedFishWeekResource/list", {
        params: {
          userId: userId
        }
      }).then(res => {
        this.tableData = res.data;
      })
      request.get("/saltedFishInitData/detail", {
        params: {
          userId: userId
        }
      }).then(res => {
        if (res.data !== null) {
          this.form = res.data;
        }
        this.loading = false
      })
    },
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.form.id) {  // 更新
            request.put("/saltedFishInitData/update", this.form).then(res => {
              if (res.code === '0') {
                this.$router.push({path: '/saltedFish/goldFishResult', query: {userId: this.form.userId}})
              } else {
                this.$message({
                  type: "error",
                  message: res.msg
                })
              }
              this.loading = false
            })
          } else {  // 新增
            let userStr = sessionStorage.getItem("user") || "{}"
            let user = JSON.parse(userStr)
            this.form.userId = user.id;
            request.post("/saltedFishInitData/save", this.form).then(res => {
              if (res.code === '0') {
                this.$router.push('/saltedFish/goldFishResult')
              } else {
                this.$message({
                  type: "error",
                  message: res.msg
                })
              }
              this.loading = false
            })
          }
        }
      })
    },
    saveWeek() {
      this.loading = true;
      if (this.formWeek.id) {  // 更新
        request.put("/saltedFishWeekResource/update", this.formWeek).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "更新成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.load() // 刷新表格的数据
          this.loading = false
          this.dialogVisibleWeek = false  // 关闭弹窗
        })
      } else {
        this.$message({
          type: "error",
          message: "系统异常，请重新登录后再试！"
        })
      }
    },
    handleEdit(row) {
      this.formWeek = JSON.parse(JSON.stringify(row))
      this.dialogVisibleWeek = true
    },
    handleSizeChange(pageSize) {   // 改变当前每页的个数触发
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {  // 改变当前页码触发
      this.currentPage = pageNum
      this.load()
    },
  }
}
</script>
<style scoped>
.bg {
  background-color: rgba(116, 120, 122, 0.1);
}

.menu_title {
  color: #409EFF;
  font-weight: bold;
}

/*::v-deep 这里主要的作用就是用来强制修改element默认的样式*/
::v-deep .el-table thead.is-group th {
  background: none;
  padding: 0px;
}

::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type {
  border-bottom: none; /*中间的横线去掉*/
}

::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type div.cell {
  text-align: right; /*上边文字靠右*/
}

::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type div.cell {
  text-align: left; /*下边文字靠左*/
}

/*核心代码*/
::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100px; /*斜线的长度*/
  top: 0;
  left: 0;
  background-color: grey;
  opacity: 0.2;
  display: block;
  transform: rotate(-43deg); /*调整斜线的角度*/
  transform: rotate(-70deg); /*调整斜线的角度*/
  -webkit-transform-origin: top;
  transform-origin: top;
}

::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100px; /*斜线的长度*/
  bottom: 0;
  right: 0;
  background-color: grey;
  opacity: 0.2;
  display: block;
  transform: rotate(-45deg); /*调整斜线的角度*/
  transform: rotate(-70deg); /*调整斜线的角度*/
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}

::v-deep .el-table thead.is-group th {
  height: 27.4px;
}

</style>
